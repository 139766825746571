import React, { useEffect } from "react"
import AnimateTexts from "../../../components/animations/animate-texts"
import CustomContainer from "../../../components/general/contianer"
import ManWithFaceWash from "../../../images/home/home_man_with_facewash.webp"
import gsap from "gsap"
import FormButton from "../../../components/form-components/form-button"
import AnimateFade from "../../../components/animations/animate-fade"
import { imageKitLink } from "../../../utils/fomatters"


const PhotoTextSection = ({transitionStatus}) => {

    // Image Scroll
    useEffect(() => {

        let section = document.querySelector(`.photo-text-section`);
        let img: HTMLDivElement = section.querySelector(`.photo-txt-img`);

        gsap.to(img, {
            y: section.clientHeight/8,
            ease: "none",
            scrollTrigger: {
                trigger: section,
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            }
        });
    }, [])

    return (
        <section className={"photo-text-section relative pt-12 sm:pt-24 overflow-hidden"} style={{ background: "linear-gradient(135deg, rgba(19,23,47, 1),rgba(0,0,0,1))", opacity: transitionStatus === "entered" ? "1":0, transition: "0.3s" }}>
            <CustomContainer className={"flex items-center justify-center pb-10"}>
                <AnimateTexts className={`photo_text`} transitionStatus={transitionStatus} delay={200}>
                    <h2 className={"will-change text-gray-400 font-semibold font-sans uppercase tracking-widest text-sm sm:text-sm text-center mb-4"} data-text-animate={true}>
                        GROOMD MEN'S CLUB
                    </h2>
                    <p className={"will-change text-gray-200 font-bold font-display text-xl md:text-3xl leading-tight max-w-3xl text-center px-4 sm:px-0"} data-text-animate={true}>
                        Groomd deeply cares about your grooming regime.
                        Join our club as a rewards member to earn exclusive rewards every time you shop.
                    </p>
                </AnimateTexts>
            </CustomContainer>
            <CustomContainer className={"flex items-center justify-center"}>
                <AnimateFade className={"photo_text_button"} transitionStatus={transitionStatus} delay={300}>
                    <div className={"will-change"} data-animate-fade={true}>
                        <FormButton to={"/groomd-rewards"} inverse disableArrowAnimation>
                            See Groomd Men's Club
                        </FormButton>
                    </div>
                </AnimateFade>
            </CustomContainer>
            <CustomContainer className={"flex items-center justify-center"}>
                <AnimateFade className={"photo_text_img"} transitionStatus={transitionStatus} rootClass={"w-full md:w-1/2 2xl:w-8/12 max-w-3xl"}>
                    <div data-animate-fade={true} className={"will-change justify-center flex"}>
                        <div className={"aspect-w-2 aspect-h-2 sm:aspect-h-1 w-full photo-txt-img will-change"} style={{
                            maxWidth: "400px",
                            background: `url(${imageKitLink('/product_images_v3/groomd_home_model.png', { tr: "f-webp,w-400" })}) center center / contain no-repeat` }}
                        />
                    </div>
                </AnimateFade>
            </CustomContainer>
        </section>
    )

}

export default PhotoTextSection