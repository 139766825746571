import React, { useEffect, useState } from "react"
import AnimateTexts from "../../components/animations/animate-texts"
import AnimateFade from "../../components/animations/animate-fade"
import FormButton from "../../components/form-components/form-button"
import { imageKitLink } from "../../utils/fomatters"
// import FormButton from "../../components/form-components/form-button"

const HeroSection = ({ transitionStatus }) => {

    const [size, setSize] = useState("")
    const [videoLoaded, setVideoLoaded] = useState(false)

    useEffect(() => {
        if (document.documentElement.clientWidth < 500) {
            setSize("small")
        } else {
            setSize("large")
        }
    }, [])

    return (
        <section className={"home-hero h-screen flex items-start sm:items-center justify-start custom-container"}>
            <div className={"absolute bg-background-default w-full h-screen left-0 top-0"} data-animate-fade={true}/>
            {size === "large"  && !videoLoaded && (
                <AnimateFade className={"main_video_image"} transitionStatus={transitionStatus} targetOpacity={1}>
                    <div data-animate-fade={true} style={{ background: `url(${imageKitLink('/product_images_v3/home_desktop_new.mov/ik-thumbnail.jpg')}) center center / cover` }} className={"absolute left-0 top-0 w-screen h-screen"} />
                </AnimateFade>
            )}
            {size === "small" && !videoLoaded && (
                <AnimateFade className={"main_video_image"} transitionStatus={transitionStatus} targetOpacity={1}>
                    <div data-animate-fade={true} style={{ background: `url(${imageKitLink('/product_images_v3/home_mobile_new.mov/ik-thumbnail.jpg')}) center center / cover` }} className={"absolute left-0 top-0 w-screen h-screen"} />
                </AnimateFade>
            )}
            {size === "large" && (
                <AnimateFade className={"main_video_lg"} transitionStatus={transitionStatus} targetOpacity={1}>
                    <video onCanPlay={() => {
                        setTimeout(() => {
                            setVideoLoaded(true)
                        }, 800)
                    }} data-animate-fade={true} autoPlay playsInline muted loop className={"hero-video w-screen h-screen absolute left-0 top-0 object-cover"}>
                        <source src={imageKitLink('/product_images_v3/home_desktop_new.mov', {tr: 'f-webm,ac-none'})} type={"video/webm"} />
                        <source src={imageKitLink('/product_images_v3/home_desktop_new.mov', {tr: 'f-mp4,ac-none'})} type={"video/mp4"} />
                        Your browser does not support HTML5 video.
                    </video>
                </AnimateFade>
            )}
            {size === "small" && (
                <AnimateFade className={"main_video_sm"} transitionStatus={transitionStatus} targetOpacity={1}>
                    <video onCanPlay={() => {
                        setTimeout(() => {
                            setVideoLoaded(true)
                        }, 800)
                    }} data-animate-fade={true} autoPlay playsInline muted loop className={"hero-video w-screen h-screen absolute left-0 top-0 object-cover"}>
                        <source src={imageKitLink('/product_images_v3/home_mobile_new.mov', {tr: 'f-mp4,w-450,ac-none'})} type="video/mp4"/>
                        Your browser does not support HTML5 video.
                    </video>
                </AnimateFade>
            )}
            {/*<div className={"absolute bg-black/10 w-screen h-screen left-0 top-0"} />*/}
            <div className={"max-w-2xl pt-36 sm:pt-0"}>
                <AnimateTexts className={`home_header`} transitionStatus={transitionStatus} delay={200}>
                    <p className={"font-semibold font-sans uppercase tracking-widest px-4 text-sm sm:text-base mb-2 md:mb-6 mix-blend-difference"} style={{ color: "bisque" }} data-text-animate={true}>
                        CLEAR. GLEAMING. CONFIDENT.
                    </p>
                    <h1 className={"font-extrabold font-sans text-4xl lg:text-5xl 2xl:text-7xl px-4 mix-blend-difference"} style={{ color: "bisque" }} data-text-animate={true}>
                        Minimal regimens with
                        maximum results
                    </h1>
                </AnimateTexts>

                {/*<AnimateFade className={"home_cta"} transitionStatus={transitionStatus} delay={400} targetOpacity={0.99}>*/}
                {/*    <div>*/}
                {/*        <div className={"flex mt-8 pl-4"}>*/}
                {/*            <div data-animate-fade={true}>*/}
                {/*                <FormButton to={"/collections/valentine-gifts"} disableArrowAnimation>*/}
                {/*                    Shop All*/}
                {/*                </FormButton>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</AnimateFade>*/}
            </div>
        </section>
    )
}

export default HeroSection