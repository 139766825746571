import React, { useEffect, useState } from "react"
import CustomContainer from "../../../components/general/contianer"
import AnimateTexts from "../../../components/animations/animate-texts"
import InlineLink from "../../../components/general/inline-link"
import { graphql, useStaticQuery } from "gatsby"
import { Ingredients, ProductItem } from "../../../models/product-item"
import CustomTransitionLink from "../../../components/transition-wrapper/custom-transition-link"
import StarRating from "../../../components/product-display/product-card/star-rating"
import ProductCardQuickAdd from "../../../components/product-display/product-card/product-card-quick-add"
import ProductCard from "../../../components/product-display/product-card/product-card"
import AnimateProductCard from "../../../components/animations/animate-product-card"
import AnimateFade from "../../../components/animations/animate-fade"
import useMediaQuery from "../../../components/media-query/use-media-query"
import Flickity from "react-flickity-component"
import { imageKitLink } from "../../../utils/fomatters"

const ImageGridsSection = ({ transitionStatus }) => {

    const smDown = useMediaQuery("(max-width: 500px)")

    const [flickityImages, setFlickityImages] = useState<Flickity | null>(null)
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        if (flickityImages) {
            flickityImages.on("select", () => {
                setCurrentImage(flickityImages.selectedIndex)
            })
        }
    }, [flickityImages]);

    const productsData = useStaticQuery(graphql`{
        products: allProducts(
            limit: 2
            sort: {fields: featured, order: ASC}
            filter: { disabled: { ne: true } }
        )
           {
                edges {
                    node {
                        id
                        urlString
                        averageReview
                        totalReviews
                        size
                        title
                        out_of_stock
                        categoryTitle
                        tagline
                        catalogue_images {
                            defaultImage
                            onHoverImage
                        }
                        variation {
                            mrp
                            price
                            tag
                            title
                        }
                        thumbnail
                    }
                }
           }
    }`)

    const products: ProductItem[] = productsData?.products?.edges?.map((el) => new ProductItem(el.node)) ?? [];

    return (
        <>
            <section className={"product-category-section relative py-10 md:py-14 xl:py-20 overflow-hidden"} style={{ background: "linear-gradient(0deg, rgba(202, 195, 195, 0.2),rgba(202, 195, 195, 0))", opacity: transitionStatus === "entered" ? "1" : 0, transition: "0.3s" }}>
                <CustomContainer className={"grid grid-cols-7 gap-4 md:gap-10 xl:gap-16"}>
                    <div className={"col-span-7 lg:col-span-3"}>
                        <AnimateFade className={"collection_skincare_image"} transitionStatus={transitionStatus} delay={200}>
                            <div className={"w-full aspect-w-5 aspect-h-5 sm:aspect-h-2 lg:aspect-h-6 bg-background-dark rounded-2xl"} data-animate-fade={true} >
                               <img alt={"Groomd Skincare"} src={imageKitLink('/product_images_v3/face_model.jpg', { tr: 'f-webp,w-800' })}
                                    loading={"lazy"}
                                    className={"object-cover rounded-2xl"}
                                    />
                           </div>
                        </AnimateFade>
                    </div>
                    <div className={"col-span-7 md:col-span-4"}>
                        <AnimateTexts className={`skincare_heading`} transitionStatus={transitionStatus} delay={250}>
                            <h2 className={"will-change text-primary-default font-bold font-display text-2xl md:text-4xl leading-[1.1] md:leading-tight px-0 max-w-md"} data-text-animate={true}>
                                Skincare can be overwhelming, so we’ve made it easy for you.
                            </h2>
                            <p className={"mt-3 will-change text-gray-600 font-sans font-medium text-base md:text-xl leading-tight px-0 max-w-md"} data-text-animate={true}>
                                Explore our collection of hero products that fit seamlessly into any routine.
                            </p>
                        </AnimateTexts>
                        <AnimateFade className={"collection_skincare_link"} transitionStatus={transitionStatus} delay={250}>
                            <div className={"mt-6"} data-animate-fade={true}>
                                <InlineLink to={"/collections/skincare"} className={"will-change text-primary-default font-sans font-medium text-base leading-tight px-4 sm:px-0 max-w-md"}>
                                    Shop All Skincare
                                </InlineLink>
                            </div>
                        </AnimateFade>

                        {!smDown && (
                            <AnimateProductCard className={`featured_products`} transitionStatus={transitionStatus} delay={500}>
                                <div className={"mt-8 sm:mt-6 grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6"}>
                                    {
                                        products.map((p) => {
                                            return (
                                                <div key={p.id} data-card-animate={true}>
                                                    <div className={"relative flex flex-col h-full w-full rounded-2xl overflow-hidden border-2 border-background-default hover:border-background-light transition duration-300 hover:shadow-special "}>
                                                        <div className={"relative w-full aspect-w-8 aspect-h-6 lg:aspect-h-5"}>
                                                            <CustomTransitionLink to={"/products/" + p.urlString}>
                                                                <div className={"absolute w-full h-full"} style={{ background: `#e7e8e3`, }}>
                                                                    <img className={`w-full h-full absolute left-0 top-0 object-contain`} src={p.catalogue_images.imageKitLink("default")} alt={p.title} loading={"lazy"}/>
                                                                </div>
                                                            </CustomTransitionLink>
                                                        </div>
                                                        <div style={{ background: "linear-gradient(rgba(231,232,227,1), rgba(231,232,227,0))" }} className={"px-4 pb-4 lg:px-6 lg:pb-6 h-full flex flex-col"}>
                                                            <CustomTransitionLink to={"/products/" + p.urlString}>
                                                                {/*Product Title*/}
                                                                <div className={"hidden lg:block mb-px"}>
                                                                    <StarRating color={"rgb(234,180,49)"} size={14} averageReview={p.averageReview} totalReviews={0}/>
                                                                </div>
                                                                <div className={"block lg:hidden mb-px"}>
                                                                    <StarRating color={"rgb(234,180,49)"} size={12} averageReview={p.averageReview} totalReviews={0}/>
                                                                </div>
                                                                <p className={"font-display font-medium text-xl"}>
                                                                    {p.title}
                                                                </p>
                                                                <p className={"font-sans text-base mr-2 opacity-70"}>
                                                                    {p.tagline}
                                                                </p>
                                                            </CustomTransitionLink>
                                                            <div className={"pt-2 sm:pt-2 mt-auto"}>
                                                                <ProductCardQuickAdd small product={p}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </AnimateProductCard>
                        )}
                        {smDown && (
                            <AnimateFade className={`skincare_features_small`} transitionStatus={transitionStatus} delay={300} stagger={0.1}>
                                <div className={"product_skincare_slider mt-4"}>
                                    <Flickity
                                        flickityRef={el => setFlickityImages(el)}
                                        options={{ friction: 0.4, prevNextButtons: false, pageDots: false, cellAlign: "left" }}
                                    >
                                        {
                                            products.map((p) => {
                                                return (
                                                    <div className={'absolute mr-4'} key={p.id} data-animate-fade={true}>
                                                        <div className={"flex flex-col h-full w-full rounded-2xl overflow-hidden border-2 border-background-default hover:border-background-light transition duration-300 hover:shadow-special "}>
                                                            <div className={"relative w-full aspect-w-8 aspect-h-6 lg:aspect-h-5"}>
                                                                <CustomTransitionLink to={"/products/" + p.urlString}>
                                                                    <div className={"absolute w-full h-full"} style={{ background: `#e7e8e3`, }}>
                                                                        <img className={`w-full h-full absolute left-0 top-0 object-contain`} src={p.catalogue_images.imageKitLink("default")} alt={p.title} loading={"lazy"}/>
                                                                    </div>
                                                                </CustomTransitionLink>
                                                            </div>
                                                            <div style={{ background: "linear-gradient(rgba(231,232,227,1), rgba(231,232,227,0))" }} className={"px-4 pb-4 lg:px-6 lg:pb-6 h-full flex flex-col"}>
                                                                <CustomTransitionLink to={"/products/" + p.urlString}>
                                                                    {/*Product Title*/}
                                                                    <div className={"hidden lg:block mb-px"}>
                                                                        <StarRating color={"rgb(234,180,49)"} size={14} averageReview={p.averageReview} totalReviews={0}/>
                                                                    </div>
                                                                    <div className={"block lg:hidden mb-px"}>
                                                                        <StarRating color={"rgb(234,180,49)"} size={12} averageReview={p.averageReview} totalReviews={0}/>
                                                                    </div>
                                                                    <p className={"font-display font-medium text-xl"}>
                                                                        {p.title}
                                                                    </p>
                                                                    <p className={"font-sans text-base mr-2 opacity-70"}>
                                                                        {p.tagline}
                                                                    </p>
                                                                </CustomTransitionLink>
                                                                <div className={"pt-2 sm:pt-2 mt-auto"}>
                                                                    <ProductCardQuickAdd small product={p}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Flickity>
                                </div>
                            </AnimateFade>
                        )}

                    </div>
                </CustomContainer>
                <CustomContainer>
                    <div className={"my-12 h-px w-full bg-background-lighter"}/>
                </CustomContainer>
                <CustomContainer className={"grid grid-cols-7 gap-4 md:gap-10 xl:gap-16"}>

                    <div className={"block lg:hidden col-span-7"}>
                        <AnimateFade className={"collection_hair_styling_image"} transitionStatus={transitionStatus} delay={200}>
                            <div className={"w-full aspect-w-5 aspect-h-3 bg-background-dark rounded-2xl"} data-animate-fade={true} >
                                <img alt={"Groomd Hair Styling"} src={imageKitLink('/product_images_v3/clay_extra.jpg', { tr: "f-webp,w-500" })}
                                     loading={"lazy"}
                                     className={"object-cover rounded-2xl"}
                                />
                            </div>
                        </AnimateFade>
                    </div>
                    <div className={"col-span-7 md:col-span-3"}>
                        <AnimateTexts className={`hair_style_heading`} transitionStatus={transitionStatus} delay={250}>
                            <h2 className={"will-change text-primary-default font-bold font-display text-xl md:text-4xl leading-tight px-0 max-w-md"} data-text-animate={true}>
                                With our Hair Styling Clay, you’re the boss of your hair
                            </h2>
                            <p className={"mt-3 will-change text-gray-600 font-sans font-medium text-base md:text-xl leading-tight px-0 max-w-md"} data-text-animate={true}>
                                We’ve created the perfect hair styling clay that will provide a pliable hold, texture, and volume.
                            </p>
                        </AnimateTexts>
                        <AnimateFade className={"collection_hair_styling_link"} transitionStatus={transitionStatus} delay={250}>
                            <div className={"mt-6"} data-animate-fade={true}>
                                <InlineLink to={"/products/hair-styling-clay"} className={"will-change text-primary-default font-sans font-medium text-base leading-tight px-4 sm:px-0 max-w-md"}>
                                    Shop Hair Styling
                                </InlineLink>
                            </div>
                        </AnimateFade>
                    </div>
                    <div className={"hidden lg:block col-span-4"}>
                        <AnimateFade className={"collection_hair_styling_image"} transitionStatus={transitionStatus} delay={200}>
                            <div className={"w-full aspect-w-5 aspect-h-3 bg-background-dark rounded-2xl"} data-animate-fade={true} >
                                <img alt={"Groomd Hair Styling"} src={imageKitLink('/product_images_v3/clay_extra.jpg', { tr: "f-webp,w-800" })}
                                     loading={"lazy"}
                                     className={"object-cover rounded-2xl"}
                                />
                            </div>
                        </AnimateFade>
                    </div>
                </CustomContainer>
            </section>
        </>
    )

}

export default ImageGridsSection