import React, { useEffect, useState } from "react"
import CustomContainer from "../../../components/general/contianer"
import AnimateTexts from "../../../components/animations/animate-texts"
import Flickity from "react-flickity-component"
import StarRating from "../../../components/product-display/product-card/star-rating"
import "./testimonials-section.css"
import gsap from "gsap"
import AnimateScale from "../../../components/animations/animate-scale"
import { FiArrowLeft } from "@react-icons/all-files/fi/FiArrowLeft"
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight"
import { imageKitLink } from "../../../utils/fomatters"

const TestimonialsSection = ({ transitionStatus }) => {

    const [flickity, setFlickity] = useState<Flickity | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (flickity){
            flickity.on('select', () => {
                setCurrentIndex(flickity.selectedIndex);
            })
        }
    }, [flickity]);

    const goNext = () => {
        flickity?.next();
        flickity.stopPlayer();
        setTimeout(() => {
            flickity.playPlayer();
        }, 1000)
    }

    const goPrevious = () => {
        flickity?.previous();
        flickity.stopPlayer();
        setTimeout(() => {
            flickity.playPlayer();
        }, 1000)
    }

    const goTo = (index) => {
        flickity.selectCell(index);
        flickity.stopPlayer();
        setTimeout(() => {
            flickity.playPlayer();
        }, 1000)
    }

    const testimonials: {
        name: string
        text: string
        speed: number
        image?: string
        type?: string
        bg?: string
    }[] = [
        {
            name: "Meet C",
            text: "The products are all vegan. It's rare to find such good combos. Great product.",
            speed: -2,
            image: "",
            type: "text",
            bg: "bg-yellow-900"
        },
        {
            name: "Neeraj C",
            text: "I have designed my whole skin-care routine around this set and I have zero regrets. Would recommend it for everyone.",
            speed: 3,
            image: imageKitLink("/web_assets/p-3.png", {  tr: 'f-webp,w-120' })
        },
        {
            name: "Tushaar J",
            text: "Moisturizes the skin well and doesn't feel greasy. Premium quality product.",
            speed: -1,
            image: imageKitLink("/web_assets/p2-2.jpeg", {  tr: 'f-webp,w-120' })
        },
        {
            name: "Anug G",
            text: "Worked very well for my oily skin. A definite recommendation for anyone with oily skin.",
            speed: 0,
            image: imageKitLink("/web_assets/p2-1.jpeg", {  tr: 'f-webp,w-120' })
        },
        {
            name: "Noel K",
            text: "All the face products remove the dead skin cells very well. The clay works amazingly as well.",
            speed: -3,
            type: "text"
        },
        {
            name: "Sonu S",
            text: "Brilliant choice for anyone with dry skin or acne. I have witnessed impressive results within a few weeks of use.",
            speed: -1,
            type: "text",
            bg: "bg-blue-700",
        },
        {
            name: "Karan S",
            text: "The all natural ingredients are a great attraction. Great performance as well.",
            speed: 1,
            image: imageKitLink("/web_assets/p2-3.jpeg", {  tr: 'f-webp,w-120' })
        },
    ];

    // Image Scroll
    useEffect(() => {

        let section = document.querySelector(`.testimonials_section`);
        let img: NodeListOf<HTMLDivElement> = section.querySelectorAll(`.testimonial_profiles`);

        img.forEach((i) => {
            let speed:number = parseInt(i.dataset.animateSpeed);
            gsap.to(i, {
                y: -35 * speed,
                ease: "none",
                scrollTrigger: {
                    trigger: section,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: 2,
                }
            });
        })

    }, [])

    return (
        /*bg-background-dark*/
        <section className={"py-24  relative testimonials_section overflow-hidden"}>
            <div className={"testimonials"}>
                <CustomContainer className={"relative"}>
                    <AnimateTexts className={"testimonials_header"} transitionStatus={transitionStatus} delay={200}>
                        <h2 className={"font-semibold font-sans uppercase text-center tracking-widest text-sm sm:text-sm mb-6 text-gray-800"} data-text-animate={true}>
                            what our customers say
                        </h2>
                    </AnimateTexts>
                    <AnimateScale className={`testimonials_profiles_wrapper`} duration={0.6} stagger={0.08} transitionStatus={transitionStatus} delay={250}>
                        <div className={"flex w-full justify-center items-center flex-nowrap py-4"}>
                            {
                                testimonials.map((t, index) => {

                                    const even = index%2===0;
                                    const center = index === Math.ceil((testimonials.length / 2)) - 1
                                    const bg = t.bg ?? (center ? "bg-primary-default" : (even ? "bg-primary-default" : "bg-primary-light"))

                                   return  <div onClick={() => { goTo(index) }} data-animate-speed={t.speed} key={`t_profiles_${index}`} className={"testimonial_profiles relative cursor-pointer"}>
                                       <div data-animate-scale={true} className={`relative flex justify-center items-center mx-8 rounded-full flex-shrink-0 ${center ? "h-36 w-36" : (even ? "w-16 h-16" : "w-24 h-24")} ${center ? "shadow-2xl" : "shadow-lg"} ${bg ?? ""}`}>
                                           {t.image && (
                                               <img src={`${t.image}&w-150`} loading={"lazy"} alt={`Testimonial of ${t.name}`} className={"w-full h-full absolute top-0 left-0 object-cover rounded-full"}/>
                                           )}
                                           {t.type === "text" &&
                                               <p  className={`font-bold font-display uppercase ${even ? "text-3xl" : "text-6xl"} text-background-light transform -translate-y-1`}>{t.name.toLowerCase()[0]}</p>
                                           }
                                       <div className={`absolute w-full h-full rounded-full ${center ? "border-8" : (even ? "border-4" : "border-4")} border-blue-500 transition duration-200 transform left-0 top-0 ${currentIndex === index ? "opacity-100 scale-125" : "opacity-0 scale-100"}`} />
                                       </div>
                                    </div>
                                })
                            }
                        </div>
                    </AnimateScale>
                    <Flickity
                        flickityRef={el => setFlickity(el)}
                        options={{
                            wrapAround: true,
                            autoPlay: 4000,
                            initialIndex: 3,
                            cellAlign: 'center',
                            friction: 0.3, freeScroll: false, prevNextButtons: false, pageDots: false, contain: false,
                        }}
                    >
                        {
                            testimonials.map((t,index) => {
                                return (
                                    <div className={"w-full"} key={`testimonials_content_`+index}>
                                        <div>
                                            <div className={"relative overflow-hidden h-full w-full"}>
                                                <div className={"p-5 flex flex-col justify-center items-center space-y-5 h-full"}>
                                                    <AnimateTexts className={`testimonials_content_`+index} transitionStatus={transitionStatus} delay={400}>

                                                        <div className={"flex flex-col items-center w-full justify-center"}>
                                                            <StarRating
                                                                size={24}
                                                                averageReview={5}
                                                                totalReviews={0}
                                                            />
                                                            <p className={"bg-background-light rounded-xl py-1 px-4 text-sm font-semibold tracking-wide mt-2"} style={{  }}>
                                                                {t.name}
                                                            </p>
                                                        </div>
                                                        <p className={"mt-8 font-bold font-display text-2xl md:text-3xl text-center leading-tight max-w-md text-gray-900 "} data-text-animate={true}>
                                                            {t.text}
                                                        </p>
                                                    </AnimateTexts>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Flickity>
                    <div className={"flex justify-center"}>
                        <div style={{ padding: "12px 16px", borderRadius: "16px", background: "rgba(240,240,240, 0.3)", backdropFilter: "blur(40px)", WebkitBackdropFilter: "blur(40px)"  }} onClick={() => { goNext() }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {
                                    testimonials.map((_, index) => {
                                        return <div key={`testimonials_indicator_`+index} style={{ width: "8px", height: "8px", marginRight: (index + 1) === testimonials.length ? "" :  "8px", borderRadius: "50%", background: currentIndex === index ? "rgb(40,40,40)" : "rgba(210,210,210, 1)" }}/>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"absolute top-1/2 left-0 justify-center transform -translate-y-1/2"} >
                        <div style={{ padding: "12px 16px", borderRadius: "16px", background: "rgba(240,240,240, 0.3)", backdropFilter: "blur(40px)", WebkitBackdropFilter: "blur(40px)"  }} onClick={() => { goPrevious() }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <FiArrowLeft size={24}/>
                            </div>
                        </div>
                    </div>
                    <div className={"absolute top-1/2 right-0 justify-center transform -translate-y-1/2"}  >
                        <div style={{ padding: "12px 16px", borderRadius: "16px", background: "rgba(240,240,240, 0.3)", backdropFilter: "blur(40px)", WebkitBackdropFilter: "blur(40px)"  }} onClick={() => { goNext() }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <FiArrowRight size={24}/>
                            </div>
                        </div>
                    </div>
                </CustomContainer>
            </div>
        </section>
    )

}

export default TestimonialsSection;