import React from "react"
import PromisesSection from "../page-components/home-components/promises-section/promises-section"
import SEO from "../components/seo/seo"
import HeroSection from "../page-components/home-components/hero-section"
import PhotoTextSection from "../page-components/home-components/photo-text-section/photo-text-section"
import ImageGridsSection from "../page-components/home-components/image-grids-section/image-grids-section"
import Footer from "../components/footer/footer"
import TestimonialsSection from "../page-components/home-components/testimonials-section/testimonials-section"

const IndexPage = ({ transitionStatus }) => {

    return (
        <>
            <SEO description={"Our Product Range Offers Men's Grooming Products which are 100% Vegan & Cruelty Free, Free of Harmful Chemicals - developed with handpicked natural ingredients & premium essential oils."} title={"Groomd Official Website • Men's Grooming"}/>
            <HeroSection transitionStatus={transitionStatus}/>
            <PromisesSection transitionStatus={transitionStatus}/>
            <PhotoTextSection transitionStatus={transitionStatus}/>
            <ImageGridsSection transitionStatus={transitionStatus}/>
            <TestimonialsSection transitionStatus={transitionStatus}/>
            <Footer transitionStatus={transitionStatus}/>
        </>
    )
}

export default IndexPage
