import { useEffect, useRef, useState } from "react"
import gsap from "gsap";
import React from "react";
import "./animate-scale.css";
import useOnScreen from "./on-screen"

const AnimateScale = ({children, className, duration=1, stagger=0.08, delay=0, transitionStatus}: {children: React.ReactNode, className: string, duration?: number, stagger?: number, delay?: number, transitionStatus: string}) =>  {

    const [revealed, setRevealed] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const reveal = useOnScreen(ref);

    useEffect(() => {
        if (reveal && !revealed) {
            let objs = document.querySelectorAll(`.${className} [data-animate-scale="true"]`);
            setTimeout(() => {
                gsap.to(objs, {
                    duration: duration,
                    transform: "translate3d(0px, 0%, 0px) scale3d(1,1,1)",
                    opacity: 1,
                    ease: "power4",
                    stagger: stagger
                });
            }, delay)
            setRevealed(true);
        }

    }, [reveal]);

    useEffect(() => {
        let objs = document.querySelectorAll(`.${className} [data-animate-scale="true"]`);

        if (transitionStatus === 'entering') {
            if (reveal) {
                setTimeout(() => {
                    gsap.to(objs, {
                        duration: duration,
                        transform: "translate3d(0px, 0%, 0px) scale3d(1,1,1)",
                        opacity: 1,
                        ease: "power4",
                        stagger: stagger
                    });
                }, delay)
            }
        }
        if (transitionStatus === 'exiting') {
            if (objs) {
                gsap.to(objs, {
                    duration: 0.3,
                    opacity: 0,
                    ease: "power4",
                    y: 20,
                    stagger: 0
                });
            }
        }
    }, [transitionStatus]);

    return (
        <div ref={ref} className={className}>
            {children}
        </div>
    )

}

export default AnimateScale;