import React, { useEffect } from "react"
import SkinType from "../../../images/promises/all_skintype.svg"
import Vegan from "../../../images/promises/vegan.svg"
import CrueltyFree from "../../../images/promises/cruelty_free.svg"
import Dermatologist from "../../../images/promises/derma.svg"
import SmallBatches from "../../../images/promises/small_batches.svg"
import ChemicalsFree from "../../../images/promises/checimals.svg"
import CustomContainer from "../../../components/general/contianer"
import AnimateTexts from "../../../components/animations/animate-texts"
import "./promises-section.css"
import AnimateScale from "../../../components/animations/animate-scale"
import AnimateFade from "../../../components/animations/animate-fade"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import FormButton from "../../../components/form-components/form-button"
import { imageKitLink } from "../../../utils/fomatters"

gsap.registerPlugin(ScrollTrigger);

const PromisesSection = ({transitionStatus}) => {

    let promises = [
        {
            image: Vegan,
            alt: "100% Vegan",
            caption: "100% Vegan"
        },
        {
            image: CrueltyFree,
            alt: "Cruelty Free",
            caption: "Cruelty Free"
        },
        {
            image: SkinType,
            alt: "For All Skin Types",
            caption: "For All Skin Types"
        },
        {
            image: ChemicalsFree,
            alt: "No Parabens & Sulphates",
            caption: "No Parabens & Sulphates"
        },
        {
            image: SmallBatches,
            alt: "Crafted in small batches",
            caption: "Crafted in small batches"
        },
        {
            image: Dermatologist,
            alt: "Dermatologist Approved",
            caption: "Dermatologist Approved"
        }
    ]

    // Image Scroll
    useEffect(() => {

        let section = document.querySelector(`.promises-section`);
        let img: HTMLDivElement = section.querySelector(`.promises-image-wrapper`);

        gsap.to(img, {
            y: -64,
            ease: "none",
            scrollTrigger: {
                trigger: section,
                start: "top center",
                end: "bottom top",
                scrub: 0.8,
            }
        });
    }, [])

    return (
        <section className={"promises-section overflow-hidden relative py-20 sm:py-40 bg-background-default"}>
            <AnimateFade className={"promises_bg_animation"} transitionStatus={transitionStatus} delay={100}>
                <div data-animate-fade={true} className={`c-home-intro_circle -big`}/>
                <div data-animate-fade={true} className={`c-home-intro_circle -small`} />
            </AnimateFade>
            <AnimateScale className={`promises-image-wrapper`} duration={1.5} stagger={0.2} transitionStatus={transitionStatus} delay={250}>
                <div className="promises-image-container _1" data-animate-scale={true}>
                    <img aria-hidden={true}
                        src={imageKitLink("/product_images_v3/serum_extra.jpg", { tr: 'f-webp,w-160' })}
                        loading="lazy" width="150" alt="Groomd products submerged in water."  className={"w-full h-full object-cover"}/>
                </div>
                <div className="promises-image-container _2" data-animate-scale={true}>
                    <img aria-hidden={true}
                        src={imageKitLink("/product_images_v3/peel_off_model.jpg", { tr: 'f-webp,w-160' })}
                        loading="lazy" width="160" alt="Man pointing at us."  className={"w-full h-full object-cover"}/>
                </div>
                <div className = "promises-image-container _3" data-animate-scale={true}>
                    <img aria-hidden={true}
                        src={imageKitLink("/web_assets/promises_img_2.jpg", { tr: 'f-webp,w-160' })}
                        loading="lazy" width="130" alt="Ficus plant with green leaves." className={"w-full h-full object-cover"}/>
                </div>
            </AnimateScale>
            <CustomContainer className={"flex items-center justify-center"}>
                <AnimateTexts className={`promise_text`} transitionStatus={transitionStatus} delay={200}>
                    <h2 className={"text-gray-500 font-semibold font-sans uppercase tracking-widest text-sm sm:text-sm text-center mb-4"} data-text-animate={true}>
                        What we stand for
                    </h2>
                    <p className={"text-gray-800 font-bold font-display text-xl md:text-3xl leading-tight max-w-4xl text-center px-4 sm:px-0"} data-text-animate={true}>
                        Groomd standards are uncompromising. We are a 100% vegan and cruelty-free brand — we believe grooming
                        for men should be easy, effortless, and natural.
                    </p>
                </AnimateTexts>
            </CustomContainer>
            <CustomContainer className={"relative flex items-center justify-center mt-8 md:mt-16"}>
                <AnimateFade className={"promises_icons"} rootClass={"max-w-5xl grid grid-cols-2 md:grid-cols-3 gap-6 px-4 md:px-0"} transitionStatus={transitionStatus} delay={300}>
                    {
                        promises.map((promise) => {
                            return <div key={promise.alt} className={"flex items-center"} data-animate-fade={true}>
                                <img loading={"lazy"} src={promise.image} alt={promise.alt} className={'w-4 md:w-5'}/>
                                <p className={"font-sans text-sm md:text-base pl-4 pr-4 md:pr-10"}>
                                    {promise.caption}
                                </p>
                            </div>

                        })
                    }
                </AnimateFade>
            </CustomContainer>
            <CustomContainer className={"relative flex items-center justify-center mt-8 md:mt-16"}>
                <AnimateFade className={"promises_button"} transitionStatus={transitionStatus} delay={300}>
                    <div data-animate-fade={true}>
                        <FormButton to={"/about"} disableArrowAnimation >
                            About Us
                        </FormButton>
                    </div>
                </AnimateFade>
            </CustomContainer>
        </section>
    )

}

export default PromisesSection;